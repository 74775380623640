import styles from './Button.css'

export function ButtonPrimary({ dataX, onClick, children, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentPrimary, layoutClassName)}{...{ dataX, onClick, children }} />
}

export function ButtonSecondary({ dataX, onClick, children, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentSecondary, layoutClassName)} {...{ dataX, onClick, children }} />
}

export function ButtonTertiary({ dataX, onClick, children, layoutClassName = undefined }) {
  return <ButtonBase className={cx(styles.componentTertiary, layoutClassName)} {...{ dataX, onClick, children }} />
}

function ButtonBase({ className, onClick, dataX, children }) {
  return (
    <button
      type='button'
      className={cx(styles.componentBase, className)}
      data-x={dataX}
      {...{ onClick }}
    >
      <span className={styles.psuedo}>{children}</span>
      <span className={styles.children}>{children}</span>
    </button>
  )
}

export function ButtonLinkPrimary({ href, dataX, children, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkPrimary, layoutClassName)} {...{ href, dataX, children }} />
}

export function ButtonLinkSecondary({ href, dataX, children, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkSecondary, layoutClassName)} {...{ href, dataX, children }} />
}

export function ButtonLinkTertiary({ href, dataX, children, layoutClassName = undefined }) {
  return <ButtonLinkBase className={cx(styles.componentLinkTertiary, layoutClassName)} {...{ href, dataX, children }} />
}

function ButtonLinkBase({ className, href, dataX, children }) {
  const protocols = ['http', 'https', 'tel', 'mailto']
  const hasProtocol = protocols.some(x => href.startsWith(x))
  const target = hasProtocol ? '_blank' : '_self'
  const rel = hasProtocol ? 'noopener noreferrer' : null

  return (
    <a
      className={cx(styles.componentLinkBase, className)}
      data-x={dataX}
      {...{ href, rel, target }}
    >
      <span className={styles.psuedo}>{children}</span>
      <span className={styles.children}>{children}</span>
    </a>
  )
}
