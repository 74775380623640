import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { SplitText } from 'gsap/SplitText'
import { useGSAP } from '@gsap/react'
import { getSteamFromConfig, getSteamToConfig } from '/util/gsapHelpers'
import { ButtonLinkSecondary, ButtonLinkTertiary } from '/features/buildingBlocks/Button'
import { Magnetic } from '/features/buildingBlocks/Magnetic'

import { Icon } from '/features/buildingBlocks/Icon'

import { images } from '/images'
import arrowRight from '/images/icons/arrow-right.raw.svg'

import styles from './Recipes.css'

gsap.registerPlugin(ScrollTrigger, SplitText)

const cards = [
  {
    href: 'http://ontdek.albron.nl/over-ons/stichting-albron',
    target: '_blank',
    image: {
      src: images.broodjeHummus,
      alt: 'broodje hummus met een vleugje van onze stichting',
    },
    text: 'Lees meer over de stichting',
    dataX: 'link-to-stichting'
  },
  {
    href: 'http://ontdek.albron.nl/branches',
    target: '_blank',
    image: {
      src: images.smoothie,
      alt: 'smoothie geserveerd met een warm welkom',
    },
    text: 'Lees meer over onze branches',
    dataX: 'link-to-branches'
  },
  {
    href: 'http://ontdek.albron.nl/over-ons/organisatie',
    target: '_blank',
    image: {
      src: images.jackfruit,
      alt: 'jackfruit slow cooked in 125 jaar goed doen',
    },
    text: 'Lees meer over onze organisatie',
    dataX: 'link-to-organisation'
  }
]

export function Recipes() {
  const componentRef = React.useRef()
  const headingRef = React.useRef()
  const buttonsRef = React.useRef()

  useGSAP(
    () => {
      const mm = gsap.matchMedia()

      const heading = new SplitText(headingRef.current, { type: 'words' })

      mm.add(
        {
          isMobile: '(max-width: 767px)',
          isDesktop: '(min-width: 768px)',
        },
        (ctx) => {
          const { isMobile } = ctx.conditions
          const tlMaster = gsap.timeline()

          tlMaster
            .add(tlHeading())

          function tlHeading() {
            const tl = gsap.timeline();
            tl
              .fromTo(heading.words, getSteamFromConfig(isMobile), getSteamToConfig({ each: 0.05 }))
              .to(buttonsRef.current, { autoAlpha: 1, duration: 2, ease: 'power1.out' }, '<1')
            return tl;
          }

          ScrollTrigger.create({
            trigger: componentRef.current,
            animation: tlMaster,
            start: 'top 60%',
          })
        }
      )
    }, { scope: componentRef })

  return (
    <div className={styles.component} ref={componentRef}>
      <h2 className={styles.heading} ref={headingRef}>Want wat onze gasten ook eten, sociale&nbsp;impact zit er altijd in.</h2>
      <div className={styles.buttons} ref={buttonsRef}>
        <Magnetic>
          <ButtonLinkSecondary href="https://ontdek.albron.nl/" dataX="link-to-ontdek-albron">Ontdek Albron</ButtonLinkSecondary>
        </Magnetic>

        <Magnetic>
          <ButtonLinkTertiary href="https://www.werkenbijalbron.nl/" dataX="link-to-careers">Werken bij Albron</ButtonLinkTertiary>
        </Magnetic>
      </div>
      <div className={styles.cardsContainer}>
        {
          cards.map((card, idx) => {
            const isLast = idx === cards.length - 1
            return (
              <LinkCard key={idx} layoutClassName={styles.linkCardLayout} {...{ ...card, idx, isLast }} />
            )
          })
        }
      </div>
    </div>
  )
}

function LinkCard({ href, target, image, text, idx, isLast = false, dataX, layoutClassName}) {
  const componentRef = React.useRef()

  useGSAP(() => {
    const mm = gsap.matchMedia()

    mm.add('(max-width: 767px)', () => {
      const pinTop = 15 * idx + 2
      const tlCards = gsap.timeline({
        scrollTrigger: {
          trigger: componentRef.current,
          endTrigger: '[data-link-card="2"]', // unpin when last card is in view
          start: `top ${pinTop}%`,
          end: 'bottom 50%',
          pin: true,
          pinSpacing: isLast,
          id: `LinkCard-${idx}`,
        }
      })
      .set(componentRef.current, { visibility: 'visible'})
      .to(componentRef.current, {
        scrollTrigger: {
          trigger: componentRef.current,
          end: `top ${pinTop}%`,
          scrub: 0.5,
        },
      })
    })

    mm.add('(min-width: 768px)', () => {
      const tlCards = gsap.timeline({
        scrollTrigger: {
          trigger: componentRef.current,
          start: 'top 80%',
        }
      })
      .fromTo(
        '[data-link-card]',
        { y: 200, opacity: 0 },
        { y: 0, autoAlpha: 1, duration: 1, ease: "back.out(1.7)", stagger: 0.2, clearProps: "transform" }
      )
    })
  })

  return (
    <div
      ref={componentRef}
      className={cx(styles.componentLinkCard, layoutClassName)}
      data-link-card={idx}
      {...{ href, target }}
    >
      <img className={styles.cardImage} src={image.src} alt={image.alt} />
      <div className={cx(styles.cardCta, styles.relativeToParent)}>
        <p className={styles.ctaText}>{text}</p>
        <a {...{ href, target }} className={cx(styles.link, styles.relativeToParent)} data-x={dataX}>
          <CtaIcon layoutClassName={styles.ctaIconLayout}/>
        </a>
      </div>
    </div>
  )
}

function CtaIcon({layoutClassName}) {
  return (
    <span className={cx(styles.componentCtaIcon, layoutClassName)}>
      <Icon layoutClassName={styles.iconLayout} icon={arrowRight} />
    </span>
  )
}
