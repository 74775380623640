import { gsap } from 'gsap'
import { useGSAP } from '@gsap/react'

export function Magnetic({ children, strength = 70, attract = true, layoutClassName = undefined }) {
  const componentRef = React.useRef(null)

  useGSAP(
    (_, contextSafe) => {
      const component = componentRef.current
      const moveMagnet = contextSafe(({ clientX, clientY }) => {
        const bounding = component.getBoundingClientRect()
        const centerX = bounding.left + bounding.width / 2
        const centerY = bounding.top + bounding.height / 2
        const offsetX = ((clientX - centerX) / bounding.width) * strength
        const offsetY = ((clientY - centerY) / bounding.height) * strength

        gsap.to(component, {
          duration: 1,
          x: attract ? offsetX : -offsetX,
          y: attract ? offsetY : -offsetY,
          ease: 'power1.out',
          overwrite: 'auto'
        })
      })

      const resetMagnet = contextSafe(() => {
        gsap.to(component, { duration: 1, x: 0, y: 0, ease: 'elastic.out(1, 0.3)', delay: 0.1 })
      })

      component.addEventListener('mousemove', moveMagnet)
      component.addEventListener('mouseleave', resetMagnet)

      return () => {
        component.removeEventListener('mousemove', moveMagnet)
        component.removeEventListener('mouseleave', resetMagnet)
      }
    },
    { scope: componentRef }
  )

  return (
    <div className={layoutClassName} ref={componentRef}>
      {children}
    </div>
  )
}
